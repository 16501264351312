import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "catalogue-panel" }
const _hoisted_2 = { class: "u-magrinDataGrid pt-2" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CatalogueGrid = _resolveComponent("CatalogueGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.selectMode)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, "Настройки импорта транспорта"))
        : _createCommentVNode("", true),
      _createVNode(_component_CatalogueGrid, {
        controller: 'PlanTransportImportSettings',
        columns: _ctx.columns,
        filterRowVisible: !_ctx.selectMode,
        groupPanelVisible: !_ctx.selectMode,
        headerFilterVisible: !_ctx.selectMode,
        filterPanelVisible: false,
        showPageSizeSelector: !_ctx.selectMode,
        pageSize: 10,
        pageSizes: _ctx.pageSizes,
        editingConfig: _ctx.editingConfig,
        selectionConfig: _ctx.selectionConfig,
        designConfig: _ctx.designConfig,
        getSelectedData: _ctx.getSelectedData,
        changeSelectedData: _ctx.changeSelectedData,
        selectMode: _ctx.selectMode,
        popupInfoComponent: _ctx.transportImportSettingsInfoDialog
      }, null, 8, ["columns", "filterRowVisible", "groupPanelVisible", "headerFilterVisible", "showPageSizeSelector", "pageSizes", "editingConfig", "selectionConfig", "designConfig", "getSelectedData", "changeSelectedData", "selectMode", "popupInfoComponent"])
    ])
  ]))
}